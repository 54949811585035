import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import Dashboard from './dashboard'
import { Tab, Nav } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import TarifColors from './tarifColor';
import TarifBlack from './tarifBlack';
// import Accordion from './accordion';
import '../../assets/css/header.css';

import HeaderNoh1 from '../header/headerNoh1';


class Tarifs extends React.Component {
  constructor() {
    super();
    this.state = {
      addClass: false,
      currentSrc: '',
      open: false,
      fields: {},
      errors: {},
      invisible: false
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submituservalidationForm = this.submituservalidationForm.bind(this);
  }
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });

  }
  submituservalidationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["nom"] = "";
      fields["telephone"] = "";
      this.setState({ fields: fields });

      this.setState({ invisible: true })
      setTimeout(() => {
        this.setState({ open: false })
        this.setState({ invisible: false })
      }, 2000)
    }
  }
  validateForm(value) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["nom"]) {
      formIsValid = false;
      errors["nom"] = "Merci de saisir votre nom.";
    }
    if (typeof fields["nom"] !== "undefined") {
      if (!fields["nom"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["nom"] = "Merci de saisir des caractères alphabet.";
      }
    }
    if (!fields["telephone"]) {
      formIsValid = false;
      errors["telephone"] = "Merci de saisir votre numero téléphone.";
    }
    if (typeof fields["telephone"] !== "undefined") {
      if (!fields["telephone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Veuillez entrer un numero téléphone valide.";
      }
    }
    if ((!errors['nom']) && (!errors['telephone'])) {
      errors['success'] = "Vos données ont été envoyées avec succès";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  openModal() {
    this.setState({ open: true })
  }
  closeModal() {
    this.setState({ open: false })
  }
  toggle() {
    this.setState({
      addClass: !this.state.addClass
    });
  }
  render() {

    let btnBlack = ["border"],
      btnYellow = ["border"],
      hideDiv = ["consumption priority"],
      showDiv = ["consumption priority hidden"];

    if (this.state.addClass) {
      btnBlack.push('black');
    }
    if (this.state.addClass) {
      btnYellow.push('yellow');
    }
    if (this.state.addClass) {
      hideDiv.push('hide');
    }
    if (this.state.addClass) {
      showDiv.push('show');
    }

    return (
      <div className={this.props.auth ? "connected" : "home" }>
        {this.props.auth ? (
          <HeaderNoh1/>              
            ):(
          <Header />
        )}
        <div className="content tabs tarifs">
          <div className="wrap">            
            <span className="user hidden"></span>
            {this.props.auth ? (
                <Dashboard />
                ):(
                ''
            )}
            <div className="bloc blocTarifs">
              {/*eslint-disable */}
              <div className="blocArrondi nopad">
                <div className="elt tarifs">
                  <div className="price">
                    <p>
                      <span>Ne payez que ce que vous consommez</span>
                      Pas de frais d'installation - Sans engagement<br />
                      Affranchissement au tarif Grand-Compte<br />
                      Dépôt à J pour tous les courriers soumis jusqu'à 17h30
                    </p>
                  </div>
                  <div className="border nobackground">
                    <Tab.Container id="left-tabs" defaultActiveKey="first">
                      <div>
                        <Nav variant="pills" className="center">
                          <Nav.Item>
                            <Nav.Link eventKey="first" className="border black">1 page recto noir et blanc plié en 3</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second" className="border color">1 page recto couleur plié en 3</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <TarifBlack />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <TarifColors />
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                  <div className="notif">
                  <p className="italic">* Tarifs H.T. 2025 avec les enveloppes à doubles fenêtres / pour information il n' y a pas de TVA sur les frais d'affranchissements / TVA de 20% sur le service<br/>
                  ** Tarifs affranchissement jusqu'à 50 grs<br/>
                  *** Tarifs affranchissement jusqu'à 20 grs</p>
                  </div>
                  <div className="question">
                    <p>
                      L'affranchissement évolue en fonction du nombre de pages.<br />
                      Vous avez des besoins spécifiques ? Ou des questions sur nos prix ?<br />
                      <Link to="/contact">Contactez-nous.</Link>
                    </p>
                    <div className="center">
                      <button type='submit' className="btn bt btBlue"
                        onClick={
                          this.openModal
                        }>
                        Faites-vous rappeler
                      </button>
                      <Popup open={
                        this.state.open
                      }
                        onClose={
                          this.closeModal
                        }
                        closeOnDocumentClick>
                        <div className="wrap connexion formulaire">
                          <div className="bloc noborder modal">
                            <a className="close"
                              onClick={
                                this.closeModal
                              }>
                              &times;
                            </a>
                            <form method="post" name="userRegistrationForm"
                              onSubmit={
                                this.submituservalidationForm
                              }>
                              <div className="form-group">
                                <legend>On vous rappelle</legend>
                                <label htmlFor="nom">Nom</label>
                                <input type="text" name="nom"
                                  value={
                                    this.state.fields.nom
                                  }
                                  className={
                                    `form-control ${this.state.errors.nom ? 'is-invalid' : ''
                                    }`
                                  }
                                  onChange={
                                    this.handleChange
                                  }
                                  placeholder="Nom" />
                                <span className="error">
                                  {
                                    this.state.errors.nom
                                  }</span>
                              </div>
                              <div className="form-group">
                                <label htmlFor="societe">Société</label>
                                <input type="text" className="form-control" name="societe" placeholder="Société" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="telephone">Téléphone</label>
                                <input type="text" name="telephone"
                                  value={
                                    this.state.fields.telephone
                                  }
                                  className={
                                    `form-control ${this.state.errors.telephone ? 'is-invalid' : ''
                                    }`
                                  }
                                  onChange={
                                    this.handleChange
                                  }
                                  placeholder="Téléphone" />
                                <span className="error">
                                  {
                                    this.state.errors.telephone
                                  }</span>
                                {
                                  this.state.invisible ? (
                                    <span className="succefull hideSuccess showSuccess">
                                      {
                                        this.state.errors.success
                                      }</span>
                                  ) : (
                                    <span></span>
                                  )
                                } </div>
                              <div className="text-center bottom">
                                <button type='submit' className="btn bt btBlue">
                                  Valider
                                </button>
                                <span className="or">OU</span>
                                <span className="call">Appelez-nous au</span>
                                <a href="tel:+3309500950" className="bt btBlue" target="_blank" rel="noopener noreferrer">09 50 09 50 00 du lundi au vendredi entre 9h-12h15 et 13h45-18h </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>


    );
  }

}

function mapStateToProps(state, ownProps) {
  let a_status = state.login.authenticated;

  return {
    auth: a_status ? a_status : ''
  }
}
export default connect(mapStateToProps, null)(Tarifs);

